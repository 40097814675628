@import "variables";
@import "styles";
.ant-select-dropdown .ant-select-dropdown-placement-bottomRight {
    width: unset !important;
    .rc-virtual-list {
        width: 400px !important;
        .rc-virtual-list-holder-inner {
            width: 400px !important;
        }
    }
}