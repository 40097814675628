.gx-popover-lang-scroll {
    height: 30px !important;
}

.ant-select-item-option-content {
    white-space: normal !important;
}

.gx-app-logo-content-bg:before {
    background-color: unset;
}

.ant-btn-primary {
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    color: #ffffff !important;
    background-color: rgb(87, 71, 156) !important;
    border-color: rgb(87, 71, 156) !important;
}

a, .gx-link {
    /* color: #ba9350; */
    color: #57479c;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #ffffff !important;
    /* background-color: #cfb085 !important;
    border-color: #cfb085 !important; */
    background-color: rgb(87 71 156 / 90%) !important;
    border-color: rgb(87 71 156 / 90%) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    /* background-color: #ba9350;
    border-color: #ba9350; */
    background-color: #57479c;
    border-color: #57479c;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    /* border-color: #ba9350; */
    border-color: #57479c;
}

.ant-checkbox-checked::after {
    /* border: 1px solid #ba9350; */
    border: 1px solid #57479c;
}

.gx-layout-sider-dark {
    /* background-color: #444342; */
    background-color: #42387A;
    color: #e4e4e9;
}

.ant-layout-sider-dark .gx-app-nav, .gx-drawer-sidebar-dark .gx-app-nav {
    color: #e4e4e9;
}

.ant-layout-sider-dark .gx-sidebar-notifications, .gx-drawer-sidebar-dark .gx-sidebar-notifications {
    border-bottom-color: #e4e4e9;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    color: #AAA59A;
    /* background: #444342; */
    background: #42387A;
}

.ant-menu-dark .ant-menu-item > a {
    /* color: #AAA59A; */
    color: #e4e4e9;
    font-weight: 600;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover, .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
    /* color: #FCB53B; */
    color: #7e6ccb;
}

.gx-layout-sider-dark .gx-icon-btn:focus, .gx-layout-sider-dark .gx-icon-btn:hover {
    background-color: #444342;
}

a:hover, .gx-link:hover, a:focus, .gx-link:focus {
    /* color: #f9c03f; */
    color: #7e6ccb;
}

.ant-pagination-item-active a {
    color: #fff;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #fff;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: #fff;
    transition: all 0.3s;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: #fff;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #fff;
    border-color: #fff;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #fff;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fff;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #fff;
    box-shadow: 0 0 0 2px rgb(143 126 215 / 20%);
}

.gx-text-orange {
    color: #f9c03f !important;
}

.gx-text-gold {
    color:gold !important;
}

.gx-text-montebravo-color-1 {
    color:#ba9350 !important;
}

.gx-text-montebravo-color-2 {
    color:#cfb085 !important;
}

.gx-text-montebravo-color-3 {
    color:#ffe2b3 !important;
}

.gx-text-montebravo-color-4 {
    color:#fac76d !important;
}

.gx-text-montebravo-color-5 {
    color:#f9c03f !important;
}

.gx-text-montebravo-dark-2 {
    color: #1f1f1f !important;
}

.gx-text-montebravo-dark-3 {
    color:#34332F !important;
}

.gx-text-montebravo-dark-4 {
    color:#4e4b44 !important;
}

.gx-text-montebravo-dark-5 {
    color:#67635b !important;
}

.recharts-tooltip-item-list li {
    color:#4e4b44 !important;
}

.recharts-tooltip-item-list li {
    color:#4e4b44 !important;
}

.gx-left-line {
    border-left: 1px solid #E8E8E8;
}

.montebravo-growth-card .recharts-tooltip-item-list li:nth-child(1) {
    color:#FE9E15 !important;
}

.montebravo-growth-card .recharts-tooltip-item-list li:nth-child(2) {
    color:#f9c03f !important;
}

.montebravo-growth-card .gx-chart-up {
    color: gold;
}

.montebravo-growth-card .gx-chart-down {
    color: #67635b;
}

.gx-bg-montebravo-color-1 {
    background-color: #ba9350;
}

.gx-bg-montebravo-color-2 {
    background-color: #cfb085;
}

.gx-bg-montebravo-color-3 {
    background-color: #ffe2b3;
}
.gx-profile-pic-upload-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    padding-top: 45%;
    color: #4e4b44;
    font-size: 20px;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
}
.gx-profile-pic-upload-btn:hover {
    opacity: 1;
}

.gx-profile-banner-avatar {
    position: relative;
}

.gx-bg-montebravo-color-4 {
    background-color: #fac76d;
}

.gx-bg-montebravo-color-5 {
    background-color: #f9c03f;
}

.gx-bg-montebravo-dark-2 {
    background-color: #1f1f1f;
}

.gx-bg-montebravo-dark-3 {
    background-color: #34332F;
}

.gx-bg-montebravo-dark-4 {
    background-color: #4e4b44;
}

.gx-bg-montebravo-dark-5 {
    background-color: #67635b;
}

.ant-btn-primary:not([disabled]):not(.disabled):active, .ant-btn-primary:not([disabled]):not(.disabled).active {
    /* background-color: #d2a861 !important;
    border-color: #d2a861 !important; */
    background-color: #8f7ed7 !important;
    border-color: #8f7ed7 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #8f7ed7;
}
.gx-profile-banner {
    padding-bottom: 50px;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
    background-color: #444342;
    color: #AAA59A;
}
.gx-bottom-none {
    border-bottom: none !important;
}
.gx-month-select {
    width: 120px;
}
.gx-year-select {
    width: 90px;
}
.gx-datepicker-sm {
    height: 24px;
}
.user-group-right {
    margin-left: -30px;
}
.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: #8f7ed7;
}
.ant-input:focus, .ant-input-focused {
    border-color: #8f7ed7;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(143 126 215 / 22%);
}
.ant-picker:hover, .ant-picker-focused {
    border-color: #8f7ed7;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(143 126 215 / 20%);
}
.ant-btn:hover, .ant-btn:focus {
    color: #57479c;
    background: #fff;
    border-color: #57479c;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #57479c;
}
.montebravo-cliente-table .ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2)  > th {
    background: white;
    text-align: right;
}
/* .montebravo-cliente-table .ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2)  > th:first-of-type {
    border-right: 1px solid transparent;
} */

.montebravo-produto-table .ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2)  > th {
    background: white;
    text-align: right;
}
.montebravo-produto-table .ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2)  > th:first-of-type {
    text-align: center !important;
}
/* .montebravo-cliente-table .ant-table.ant-table-bordered .ant-table-thead > tr:nth-of-type(2)  > th:last-of-type {
    border-right: 1px solid #f0f0f0;
        border-right: 1px solid transparent;
} */

.gray-color, .gray-color:focus, .gray-color:active {
    color: #898a98 !important;
}

.gx-app-login-content .ant-input:focus {
    box-shadow: none;
    border-color: #57479c;
}
.ant-input:hover {
    border-color: #57479c;
    border-right-width: 1px !important;
}

.gx-text-gray {
    color: #898a98;
}
.ant-popover-inner-content {
    color: #898a98;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgb(143 126 215 / 26%);
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #57479c;
}

.responsive-wrap {
    max-width: 230px;
}
.d-inline-block {
    display: inline-block;
}
.d-flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.prod-view-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
@media (max-width: 420px) {
    .responsive-wrap, .responsive-wrap button {
        max-width: none;
        width: 100%;
        margin-bottom: .7rem;
    }
    .prod-view-header {
        display: inline-block;
    }
}
.gx-profile-banner {
    background-color: #57479c;
}
.ant-spin-dot-item {
    background-color: #42387A !important;
}
.ant-layout-sider .ant-menu-inline .ant-menu-item {
    padding-left: 18px !important;
}
.ant-menu-dark .ant-menu-item .icon {
    min-width: 14px;
    margin-right: 10px;
}
.ant-drawer-content {
    background-color: #42387A !important;
}
.ant-drawer-content .ant-menu {
    padding-top: 1.5rem;
}

.ant-menu-title-content a{
    color: #e4e4e9 !important;
}

.ant-select-dropdown .ant-select-dropdown-placement-bottomRight {
    width: unset !important;
    .rc-virtual-list {
        width: 100% !important;
    }
}